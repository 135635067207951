@use '@/components/colors';

.header {
    background-color: colors.$grey3;
    color: colors.$white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
}
