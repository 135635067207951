.main {
    :global(.fa-fw) {
        width: 1.5rem;
    }

    :global(.icon-success) {
        color: #4a7729;
    }

    :global(.icon-failure) {
        color: #e31937;
    }

    :global(.icon-pending) {
        color: #aaa;
    }

    :global(.icon-blank) {
        display: inline-block;
    }
}
