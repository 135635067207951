.main {
    margin-top: 0.25rem;
    font-size: 0.9rem;

    :global {
        td,
        th {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.125rem;
            padding-bottom: 0.125rem;
            vertical-align: top;
        }

        th.normalWidth {
            min-width: 10rem;
        }
    }
}
